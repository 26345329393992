<template>
  <div class="bg-white rounded w-50 mr-1 p-1">
    <h1 class="font-weight-bolder font-medium-5">
      {{ 'Assets' }}
    </h1>

    <div
      v-for="[key,values] in Object.entries(assetGroupsByCategory)"
      :key="key"
    >
      <h2 class="font-weight-bolder font-medium-3">
        {{ key }}
      </h2>
      <l-table-list-collector
        :ref="`${key}_lTableRef`"
        :is-searchable="false"
        :module-name="MODULE_NAME"
        :table-columns="packItemTableColumns"
        :fetched-data="values"
        class="tablePickedItems"
        :has-footer="false"
        show-empty-list-title
        :row-active="{
          filedKey: 'activatedPack',
          classes: 'activeRowColor',
        }"
      >
        <template #cell(state)="{data: {item}}">
          <state-of-asset
            :data="item"
            :with-label="false"
            :styles="{ color: '#00B139', width: '20px', height: '20px' }"
          />
        </template>
        <template #cell(asset_id)="{data: {item}}">

          <div class="d-flex align-items-baseline justify-content-between">

            <span>{{ getValueFromGivenObjectByKey(item,'asset_id') }}</span>
            <!--              <div class="icon-wrapper ">-->
            <!--                <feather-icon-->
            <!--                  icon="LBoxIcon"-->
            <!--                  size="18"-->
            <!--                />-->
            <!--              </div>-->
          </div>

        </template>
        <template #cell(name)="{data: {item}}">
          {{ getValueFromGivenObjectByKey(item,'product.name') }}
        </template>
        <template #cell(pack_icon)="{data: {item}}">
          <div
            :class="`icon-wrapper ${item.activatedPack && 'active'}  ${item.status.toString() !== assetStates.PICKED.code.toString() && 'disabledPackIcon'}`"
            @click="item.status.toString() === assetStates.PICKED.code.toString() && packedAsset(item.id)"
          >
            <feather-icon
              icon="LBoxIcon"
              size="18"
            />
          </div>
        </template>
      </l-table-list-collector>
    </div>
    <div class="d-flex justify-content-between">
      <span />
      <span class="font-weight-bolder mr-1">
        Total assets to be packed: {{ orderItems.assets.length }}
      </span>
    </div>
  </div>
</template>

<script >

import LTableListCollector from '@/views/components/LTableListCollector.vue'
import * as _ from 'lodash'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import { assetStates } from '@/enum/asset-statuses'
import config from '../../config'

export default {
  name: 'Assets',
  components: {
    StateOfAsset,
    LTableListCollector,
  },
  computed: {
    assetStates() {
      return assetStates
    },
    orderItems() {
      return this.$store.state[this.MODULE_NAME].orderItems
    },
    assetGroupsByCategory() {
      return _.groupBy(this.orderItems.assets, item => item.product.categories.map(category => category.name).join(', '))
    },
  },
  methods: {
    getValueFromGivenObjectByKey,
    packedAsset(id) {
      const selectedProduct = _.find(this.orderItems.assets, { id })
      if (!selectedProduct) return

      const openedContainers = _.find(this.orderItems.containers, { type_id: 3, isContainerOpen: true })
      if (!openedContainers) {
        this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEM_CONTAINERS`, [...this.orderItems.containers, selectedProduct])
      } else {
        this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEM_CONTAINERS`, _.map(this.orderItems.containers, item => (item.id === openedContainers.id ? { ...item, children: _.concat(item.children, selectedProduct) } : item)))
      }
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEM_ASSETS`, _.remove(this.orderItems.assets, (i => i.id !== id)))
    },
  },
  setup() {
    const { packItemTableColumns, MODULE_NAME } = config()
    return {
      MODULE_NAME,
      packItemTableColumns,
    }
  },
}
</script>
<style lang="scss">
.tablePickedItems{
  td,th{
    padding: 0.3rem 1rem;
  }
}
.activeRowColor{
  background: #490A7326 !important;
}

.disabledPackIcon{
  cursor: not-allowed !important;
  opacity: 0.5;
}
</style>
